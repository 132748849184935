import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin.js';

const AboutLayout = () => {

    let isMobile = window.innerWidth <= 768;
    const transitionSect = {
        duration: 1,
        ease: 'power2.inOut',
    };
    const transitionShort = {
        duration: 1.2,
        ease: 'power2.inOut',
    };

    //stop bottom scroll
    // document.querySelector('body').style.overflow = "hidden"
    // document.querySelector('.about').style.overflow = "auto"
    
    //scroll anim header
let lastScrollY = 0;

function onScroll() {
    const header = document.querySelector('header');
    // Use window.scrollY for scroll position
    const currentScrollY = window.scrollY;

    console.log('Scrolling detected', currentScrollY, lastScrollY);

    if (currentScrollY > lastScrollY && currentScrollY > 80) {
        header.classList.add('exit');
        header.classList.remove('enter');
    } else if (currentScrollY < lastScrollY) {
        header.classList.add('enter');
        header.classList.remove('exit');
    }

    lastScrollY = currentScrollY;
}

// Since window is the global object, you don't specifically check for isMobile here
// But ensure isMobile is correctly determined in your code
if (isMobile) {
    window.addEventListener('scroll', onScroll);
}



    //grayscale bg shader
    setTimeout(() => {
        const bgShader = document.querySelector('.bgShader')
        if(bgShader) bgShader.style.filter = 'grayscale(100%) contrast(150%)';
    }, 200);

    const calculateHeight = () => {
        const container = document.querySelectorAll('.about__carousel__container');
        const head = document.querySelectorAll('.about__carousel__head');
        const scrollDiv = document.querySelectorAll('.about__carousel__scroll-container');

        // if (!isMobile) {
            container.forEach((cont, index) => {
                if (scrollDiv[index] && head[index]) {
                    const heightCont = scrollDiv[index].getBoundingClientRect().height;
                    const heightHead = head[index].getBoundingClientRect().height;
                    const contPad = window.getComputedStyle(container[index]).getPropertyValue("padding-top");
                    const headPad = window.getComputedStyle(head[index]).getPropertyValue("padding-bottom");
                    const padding = parseInt(contPad, 10) * 2 + (index < 1 ? parseInt(headPad, 10) : 0);
                    const pad = isMobile ? 48 : padding
                    cont.style.height = heightCont + heightHead + padding + 'px';
                }
            });
        // }
    };
    window.onload = () => {
      calculateHeight();
    };

    // CAROUSELS
    const onResize = () => {
    isMobile = window.innerWidth <= 768;
    const scrollWidth = (cards, div, carousel) => {
        let totalWidth = 0;

        let padding = isMobile ? "16px" : "0";
        // let padding = window.getComputedStyle(cards[0]).getPropertyValue("margin-right");
        cards?.forEach(card => {
            totalWidth += card.offsetWidth + parseInt(padding, 10);
        });

        // handle odd number
        if(cards?.length%2!==0 && isMobile && carousel===2){
        totalWidth += cards[0].offsetWidth + parseInt(padding, 10);
    }

        if(carousel===2) totalWidth += 80
        
        // let paddingGeneral = window.getComputedStyle(div).getPropertyValue("padding-left");
        // totalWidth += parseInt(paddingGeneral, 10)*2;

        return totalWidth;
    };

    const head = document.querySelectorAll('.about__carousel__head');
    const container = document.querySelectorAll('.about__carousel__container');
    const scroll = document.querySelectorAll('.about__carousel__scroll')[2];
    const scrollRound = document.querySelectorAll('.about__carousel__scroll.round-scroll');
    const scrollSquare = document.querySelectorAll('.about__carousel__scroll.not-round-scroll');
    const card = document.querySelectorAll('.about__carousel__card');
    const cardRound0 = scrollRound[0].querySelectorAll('.about__carousel__round-card');
    const cardRound1 = scrollRound[1].querySelectorAll('.about__carousel__round-card');
    const cardSquare = scrollSquare[0]?.querySelectorAll('.about__carousel__card');
    const arrowLeft = document.querySelectorAll('.bg-arrow.left');
    const arrowRight = document.querySelectorAll('.bg-arrow.right');
    const scrollDiv = document.querySelectorAll('.about__carousel__scroll-container');
    const title = document.querySelectorAll('.about__carousel__title');
    
    const devide = !isMobile ? 1 : 2;
    const scrollContainerWidth = scrollWidth(card, scroll);
    const scrollContainerWidthRound0 = scrollWidth(cardRound0, scrollRound[0], 1) 
    const scrollContainerWidthRound1 = scrollWidth(cardRound1, scrollRound[1], 2) / devide
    const scrollContainerWidthRound2 = scrollWidth(cardSquare, scrollSquare[0], 3) 

    let distanceLeft = !isMobile ? title[0].getBoundingClientRect().left : 16
    // scroll.style.paddingLeft = distanceLeft + 'px';
    scrollRound[0].style.paddingLeft = distanceLeft + 'px';
    scrollRound[1].style.paddingLeft = distanceLeft + 'px';
    // scroll.style.paddingRight = distanceLeft + 'px';
    scrollRound[0].style.paddingRight = distanceLeft + 'px';
    scrollRound[1].style.paddingRight = distanceLeft + 'px';
    
    // scroll.style.width = scrollContainerWidth + distanceLeft*2 + 'px';
    if(isMobile) scrollRound[0].style.width = scrollContainerWidthRound0 + distanceLeft*2 + 'px';
    scrollRound[1].style.width = scrollContainerWidthRound1 + distanceLeft*2 + 'px';
    
    if(scrollSquare[0]){
        console.log(distanceLeft)
        scrollSquare[0].style.paddingLeft = distanceLeft + 'px';
        scrollSquare[0].style.paddingRight = distanceLeft + 'px';
        scrollSquare[0].style.width = scrollContainerWidthRound2 + 'px';
    }
    
    if(!isMobile) {
        container.forEach((cont, index)=>{
            const heightCont = scrollDiv[index].getBoundingClientRect().height;
            const heightHead = head[index].getBoundingClientRect().height;
            const contPad = window.getComputedStyle(container[index]).getPropertyValue("padding-top");
            const headPad = window.getComputedStyle(head[index]).getPropertyValue("padding-bottom");
            const padding = parseInt(contPad, 10)*2 + (index < 1 ? parseInt(headPad, 10):0);
            cont.style.height = heightCont + heightHead + padding + 'px';
        })
    }

    arrowLeft.forEach((arrow, index) => {
        arrow.style.opacity = 0.25;
        arrow.style.cursor = 'unset';
        arrow.style.pointerEvents = 'none';
        arrow.addEventListener('click', () => {
            if (scrollDiv[index]) {
                const isDesk = window.innerWidth > 1024;
                let cardDiv;
                if (index === 0) cardDiv = cardRound0[0];
                else if (index === 1) cardDiv = cardRound1[0];
                else if (index === 2) cardDiv = cardSquare[0];
                const steps =  isDesk ? 2 : 1;
                const space = cardDiv.offsetWidth * steps;
                const padding = parseInt(window.getComputedStyle(cardDiv).getPropertyValue('margin-right'), 10) * steps;
                const scrollPosition = scrollDiv[index].scrollLeft;
                const excess = scrollPosition % (space + padding);

                let newScrollPosition = scrollPosition - space - padding;
                if (excess !== 0) {
                    newScrollPosition = scrollPosition - excess;
                }
                newScrollPosition = Math.max(0, newScrollPosition);

                if(newScrollPosition == 0 ){
                    arrow.style.opacity = 0.25
                    arrow.style.cursor = "unset"
                    arrow.style.pointerEvents = 'none';
                } else {
                    arrow.style.opacity = 1;
                    arrow.style.cursor = "pointer"
                    arrow.style.pointerEvents = 'all';
                }
                arrowRight[index].style.opacity = 1
                arrowRight[index].style.cursor = 'pointer';
                arrowRight[index].style.pointerEvents = 'all';
                
                scrollDiv[index].scrollLeft = newScrollPosition;
            }
        });
    });


    arrowRight.forEach((arrow, index) => {
        arrow.addEventListener('click', () => {
            if (scrollDiv[index]) {
                const isMobile = window.innerWidth <= 768;
                const isDesk = window.innerWidth > 1024;
                
                let cardDiv
                if(index===0) cardDiv = cardRound0[0];
                else if(index===1) cardDiv = cardRound1[0];
                else if (index === 2) cardDiv = cardSquare[0];
                const steps =  isDesk ? 2 : 1;
                const space = cardDiv.getBoundingClientRect().width * steps;
                const padding = parseInt(window.getComputedStyle(cardDiv).getPropertyValue('margin-right'), 10) * steps;
                const scrollPosition = scrollDiv[index].scrollLeft;
                const extraOffset = isMobile ? padding : 0;
                const excess = (scrollPosition + padding) % (space + padding);
                let alignedPosition = scrollPosition - excess + (excess > 0 ? space + padding : 0) + extraOffset;

                if (scrollPosition === 0 || alignedPosition === scrollPosition) {
                    alignedPosition = scrollPosition + space + padding;
                }

                let maxScroll = scrollDiv[index].scrollWidth - scrollDiv[index].clientWidth
                if (maxScroll-alignedPosition<=distanceLeft) {
                  arrow.style.opacity = 0.25;
                  arrow.style.cursor = 'unset';
                  arrow.style.pointerEvents = 'none';
                } else {
                  arrow.style.opacity = 1;
                  arrow.style.cursor = 'pointer';
                  arrow.style.pointerEvents = 'all';
                }
                arrowLeft[index].style.opacity = 1;
                arrowLeft[index].style.cursor = 'pointer';
                arrowLeft[index].style.pointerEvents = 'all';

                scrollDiv[index].scrollLeft = alignedPosition;
            }
        });
    });
    }

    //touch swipe
    const handleTouchSwipe = () => {
        let startX, startY, isSwiping;

        const onTouchStart = (e) => {
        startX = e.touches[0].clientX;
        startY = e.touches[0].clientY;
        isSwiping = false; 
        };

        const onTouchMove = (e) => {
            if (!isSwiping) {
                const currentX = e.touches[0].clientX;
                const currentY = e.touches[0].clientY;
                const diffX = Math.abs(currentX - startX);
                const diffY = Math.abs(currentY - startY);

                isSwiping = diffX > diffY; 
            }

            if (isSwiping) {
                e.preventDefault(); 
            }
        };

        const onTouchEnd = (index) => (e) => {
            const endX = e.changedTouches[0].clientX;

            const threshold = 50;
            const deltaX = startX - endX;

            if (Math.abs(deltaX) > threshold) {
                if (deltaX > 0) triggerArrowClick(index, 'right');
                else triggerArrowClick(index, 'left');
            }
        };

        const triggerArrowClick = (index, direction) => {
            const arrowSelector = `.bg-arrow.${direction}`;
            const arrow = document.querySelectorAll(arrowSelector);
            if (arrow) arrow[index].click();
        };

        const scrollDivs = document.querySelectorAll('.about__carousel__scroll-container');
        scrollDivs.forEach((div, index) => {
            div.addEventListener('touchstart', onTouchStart);
            div.addEventListener('touchmove', onTouchMove);
            div.addEventListener('touchend', onTouchEnd(index));
        });
    };

    onResize();
    window.addEventListener('resize', onResize);
    if(isMobile) {
        handleTouchSwipe(); 
    }


    // GET IN TOUCH
    gsap.registerPlugin(ScrollToPlugin);
    let contactsButton = document.querySelector('.about__intro__small-text');
    let contactsSection = document.querySelector('.about__contact');
    let hoverContacts = document.querySelector('.about__intro__small-text');
    const scrollToContacts = (e) => {
        e.preventDefault()
        let mob = window.innerWidth <= 768;
        let contactsPos = contactsSection.getBoundingClientRect().top - window.innerHeight/4
        gsap.to(window, {
          scrollTo: contactsPos,
          ...transitionShort,
          onComplete: () => {
            if (mob) hoverContacts.classList.add('resetCont');
          },
        });
        if(mob) hoverContacts.classList.remove('resetCont');;
    }
    contactsButton.addEventListener('click', scrollToContacts);

    // BACK TO TOP
    let scrollToTopButton = document.querySelector('.about__back-top');
    let hoverBack = document.querySelector('.about__back-top__button-hover');
    const backToTop = () => {
        let mob = window.innerWidth <= 1024;
        gsap.to(window, {
          scrollTo: 0,
          ...transitionShort,
          onComplete: () => {
            if(mob) hoverBack.classList.add('reset');
          },
        });
        if(mob) hoverBack.classList.remove('reset');
    }
    scrollToTopButton.addEventListener('click', backToTop);

    // ENTER SECTION ANIMATION
    // const options = {
    //     root: null,
    //     rootMargin: '0px',
    //     threshold: 0.5,
    // };

    // const targets = document.querySelectorAll('.about__intro, .about__carousel, .about__partner, .about__contact, .about__back-top');
    // const observer = new IntersectionObserver((entries, observer) => {
    //     entries.forEach((entry) => {
    //     if (entry.isIntersecting) {
    //         sectionIn(entry.target);
    //         observer.unobserve(entry.target);
    //     }
    //     });
    // }, options);

    // targets.forEach((target) => {
    //     target.children[0].style.opacity = 0;
    //     observer.observe(target);
    // });

    // const sectionIn = (target) => {
    //     gsap.fromTo(
    //     target.children[0],
    //     { y: 80, opacity: 0 },
    //     { y: 0, opacity: 1, stagger: 1, ...transitionSect }
    //     );
    // };
}
export default AboutLayout;
