import BackgroundShader from "./modules/BackgroundShader";
import SubscribeForm from './modules/SubscribeForm';
import HowItWorks from './modules/HowItWorks';
import HomeLayout from './modules/HomeLayout';
import AboutLayout from './modules/AboutLayout';
import ProjectLayout from './modules/ProjectLayout';
import Journal from "./modules/Journal";
import NewsProjectsDetails from "./modules/NewsProjectsDetails";
import ErrorPage from './modules/ErrorPage';

import gsap from "gsap";

import { isHome } from "./helpers";

new class VIV {
  constructor() {
    this.routes()
    this.initMenu()

    SubscribeForm()

    this.wpAssetsPath = document.getElementById('wp-assets-path').value
  }

  initMenu() {
    const templateSelector = 'data-template'
    const dataTemplate = document.querySelector(`[${templateSelector}]`)?.getAttribute(templateSelector)

    if (dataTemplate !== 'coming-soon') {
      this.menu();
      // this.footer()
    }
  }

  routes() {
    const templateSelector = 'data-template'
    const dataTemplate = document.querySelector(`[${templateSelector}]`)?.getAttribute(templateSelector)

    // add value 'data-template' in .php & add case to switch to fire js in the right place
    switch (dataTemplate) {
      case 'coming-soon':
          BackgroundShader()
        break;
      case 'home':
          BackgroundShader()
          HowItWorks();
          HomeLayout();
        break;
      case 'about':
          AboutLayout();
          BackgroundShader();
        break;
      case 'project':
          ProjectLayout();
        break;
      case 'journal':
          Journal();
        break;
      case 'details':
          NewsProjectsDetails();
        break;
      case '404':
          ErrorPage();
          BackgroundShader();
        break;
    
      default:
        break;
    }
  }

  footer() {
    const footer = document.querySelector('.footer')
    if (isHome() && window.innerWidth > 768) {
      footer.style.height = 'calc(100vh - 246px)';
    }
  }

  menu() {
    let menuSelector = window.innerWidth > 800 ? '.menu' : '.menu__container'
    const menuToggle = document.querySelector('.menu__toggle')
    const svg = menuToggle.querySelectorAll('img')
    const logo = document.querySelector('.header__logo')
    let menuContainer = document.querySelector(menuSelector);
    const links = document.querySelectorAll('.menu__container a.mask');
    let open = false
    let timingLayer = 0
    let timingCta = 300
    let btnDisabled = false

    const updateMenuSelector = () => {
      menuSelector = window.innerWidth > 800 ? '.menu' : '.menu__container'
      menuContainer = document.querySelector(menuSelector)
    }
    
    updateMenuSelector()
    
    window.addEventListener('resize', updateMenuSelector)

    menuToggle.addEventListener('click', (e) => {
      e.preventDefault()
      if (btnDisabled) return
      btnDisabled = true
      setTimeout(() => {
        btnDisabled = false
      }, 500);
      open = !open

      svg.forEach((icon, index) => {
        if (open) {
          setTimeout(() => {
            icon.classList.toggle("hidden");
            icon.classList.toggle("visible");
            menuToggle.classList.add('open');
          }, index * 200);
        } else {
          menuToggle.classList.remove('open');
          svg[1].classList.remove("visible")
          svg[1].classList.add("hidden")
          setTimeout(() => {
            svg[0].classList.remove("hidden")
            svg[0].classList.add("visible")
          }, 200);
        }
      });
      
      
      
      setTimeout(() => {
        menuContainer.classList.toggle('show')
      }, timingLayer);
      setTimeout(() => {
        links.forEach((link) => {
          link.classList.toggle('active')
        })
      }, timingCta);
      if (open) {
        timingCta = 0
        timingLayer = 300
        // svg.src = `${this.wpAssetsPath}/img/menu-close.svg`
        if(logo) logo.style.opacity = 0
        // svg.classList.remove('dark')
        // document.querySelector('html').style.overflowY = 'hidden'
        textMask()
      } else {
        timingCta = 300
        timingLayer = 0
        // svg.src = `${this.wpAssetsPath}/img/menu.svg`
        if(logo) logo.style.opacity = 1
        if (!isHome()) {
          svg[0].classList.add('dark')
        }
        // document.querySelector('html').style.overflowY = 'unset'
      }
      // svg.src = this.wpAssetsPath + (open ? 'img/menu-close.svg' : 'img/menu.svg')
    })
     
    const textMask = () => {
      const randomizeBackgroundPosition = () => {
        links.forEach((link) => {
          const randomPositionX = Math.floor(Math.random() * 100);
          const randomPositionY = Math.floor(Math.random() * 100);
          link.style.backgroundPosition = `${randomPositionX}% ${randomPositionY}%`;
        });
      };

      randomizeBackgroundPosition();

      const updatePosition = (event) => {
        const { offsetX, offsetY } = event;
        const { clientWidth, clientHeight } = menuContainer;

        const posX = Math.floor((offsetX*0.75 / clientWidth) * 100);
        const posY = Math.floor((offsetY*0.75 / clientHeight) * 100);

        links.forEach((link) => {
          link.style.backgroundPosition = `${posX}% ${posY}%`;
        });
      };

      menuContainer.addEventListener('mousemove', updatePosition);
    }
  }
}
