const SubscribeForm = () => {

    let error = false;
    let check = false
    const form = document.querySelectorAll('#mc-embedded-subscribe-form');
    const emailField = document.querySelectorAll('#mce-EMAIL');
    const emailError = document.querySelectorAll('.error');
    const submitField = document.querySelectorAll('#mc-embedded-subscribe');

    // set page
    const queryString = window.location.search
    if(queryString.includes("thankyou")) {
        const comingSoonPage = document.querySelector('.coming-soon__container')
        const thankyouPage = document.querySelector('.coming-soon__container.thankyou')
        comingSoonPage.style.display = "none"
        thankyouPage.style.display = 'unset';
    }
    
    form.forEach((f, i) => {

        // validation 
        form[i].addEventListener('submit', (e) => {
          e.preventDefault();

          const email = emailField[i].value.trim();
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

          if (!emailRegex.test(email)) {
            error = true;
            emailField[i].blur();
            emailError[i].classList.add('emailError');
            emailField[i].classList.add('emailError');
            submitField[i].classList.add('emailError');
          } else {
            error = false;
          }

          if (!check) {
            emailField[i].blur();
            submitField[i].classList.add('emailError');
          } 

          if (check && emailRegex.test(email)) {
            form[i].submit();
          }

          // custom checkbox
          const checkboxes = document.querySelectorAll('.custom-checkbox');
          checkboxes.forEach(function (checkbox) {
            checkbox.addEventListener('change', function () {
              const checkmark =
                this.nextElementSibling.querySelector('.checkmark');
                if (this.checked) {
                    check = true;
                    checkmark.style.display = 'block';
                    submitField[i].classList.remove('emailError');
                } else {
                    check = false;
                    checkmark.style.display = 'none';
                    submitField[i].classList.add('emailError');
                }
            });
          });
        });

        // reset
        emailField[i].addEventListener('focus', () => {
            if(error){
                // emailField.value = '';
                emailError[i].classList.remove('emailError');
                emailField[i].classList.remove('emailError');
                submitField[i].classList.remove('emailError');
            }
        })
    });
};

export default SubscribeForm;
