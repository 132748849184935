const NewsProjectsDetails = () => {
  if (!document.querySelectorAll('.carousel')) return
  const mobile = window.innerWidth <= 768
  const carousels = [...document.querySelectorAll('.carousel')]

  const calculatePadding = () => {
    const windowWidth = window.innerWidth
    const maxWidth = 1440
    if (windowWidth <= maxWidth) {
      return 0;
    }
    return Math.floor((windowWidth - maxWidth) / 2)
  }

  const applyPaddingToCarousels = () => {
    const padding = calculatePadding() + 72
    carousels.forEach(carousel => {
      carousel.querySelector('.carousel__cardscroll').style.paddingLeft = `${padding}px`
    })
  }

  const scrollByItems = (container, direction) => {
    const itemWidth = container.children[0].offsetWidth
    const padding = mobile ? 16 : 32
    const scrollAmount = itemWidth + padding
    const newScrollPosition = container.scrollLeft + (direction * scrollAmount)
    
    container.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth'
    })

    updateButtonStates(container, newScrollPosition)
  }

  const updateButtonStates = (container, scrollPosition = null) => {
    const btnLeft = container.parentElement.querySelector('.left')
    const btnRight = container.parentElement.querySelector('.right')

    const currentScrollPosition = scrollPosition !== null ? scrollPosition : container.scrollLeft

    if (currentScrollPosition <= 0) {
      btnLeft.classList.add('disable')
    } else {
      btnLeft.classList.remove('disable')
    }

    if (currentScrollPosition + container.clientWidth >= container.scrollWidth) {
      btnRight.classList.add('disable')
    } else {
      btnRight.classList.remove('disable')
    }
  }

  carousels.forEach(carousel => {
    const containerScroll = carousel.querySelector('.carousel__cardscroll')
    const btnLeft = carousel.querySelector('.left')
    const btnRight = carousel.querySelector('.right')

    btnLeft.addEventListener('click', () => scrollByItems(containerScroll, -1))
    btnRight.addEventListener('click', () => scrollByItems(containerScroll, 1))

    containerScroll.addEventListener('scroll', () => updateButtonStates(containerScroll))

    updateButtonStates(containerScroll)
  })

  applyPaddingToCarousels();

  window.addEventListener('resize', applyPaddingToCarousels);


  // FAQ
  // to-do: dynamic height of answer!!!!!!!
  const question = document.querySelectorAll('.home__faq__question')
  let pad = 11 
  question.forEach((faq) => {
    let isOpen = false
    const btn = faq.querySelector('.home__faq__closed')
    const answer = faq.querySelector('.home__faq__open')
    faq.addEventListener('click', () => {
      isOpen = !isOpen

      //text height
      if (isOpen) answer.style.height = (answer.querySelector('p').clientHeight + pad)+ 'px'
      else answer.style.height = '0px'

      //open
      answer.classList.toggle('open')

      //anim height
      if (isOpen) answer.style.transition = 'height 0.2s linear, opacity 0.2s linear 0.2s'
      else answer.style.transition = 'height 0.2s linear, opacity 0.2s linear'

      faq.querySelector('.faq-open').style.display = isOpen ? 'none' : 'block'
      faq.querySelector('.faq-close').style.display = isOpen ? 'block' : 'none'
    })
  })

  const backToTopButton = document.querySelector('.home__back-top')

  backToTopButton.addEventListener('click', (e) => {
    e.preventDefault()

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  })

  // VIDEO
  if (!document.querySelectorAll('.home__first-project__right')) return
  const videoContainers = document.querySelectorAll('.home__first-project__right')
  videoContainers.forEach(container => {
    container.classList.add('cursor')
    if (!container.querySelector('video')) return
    const video = container.querySelector('video')
    const playButton = container.querySelector('.play-button')
    // const posterUrl = video.getAttribute('poster')

    const updatePlayButton = () => {
      if (video.paused || video.ended) {
        playButton.classList.remove('hidden')
      } else {
        playButton.classList.add('hidden')
      }
    }

    const handlePlayButtonClick = (e) => {
      e.stopPropagation()
      if (video.paused || video.ended) {
        video.play()
      } else {
        video.pause()
      }
    }

    const handleVideoEnded = () => {
      video.load()
      updatePlayButton()
    }

    playButton.addEventListener('click', handlePlayButtonClick)
    video.addEventListener('play', updatePlayButton)
    video.addEventListener('pause', updatePlayButton)
    video.addEventListener('ended', handleVideoEnded)

    video.addEventListener('playing', updatePlayButton)
    video.addEventListener('waiting', updatePlayButton)
    video.addEventListener('canplay', updatePlayButton)

    updatePlayButton()
  })
}

export default NewsProjectsDetails