
const HomeLayout = () => {
  
  // CTAS
  let discoverAboutCta = document.querySelector('.home__intro__small-text');
  discoverAboutCta.addEventListener('click', (e) => {
    discoverAboutCta.parentElement.querySelectorAll('.underline')[1].style.opacity = '0';
  });
  let discoverProjCta = document.querySelector('.home__first-project__left__cta');
  if(discoverProjCta) {
    discoverProjCta.addEventListener('click', (e) => {
      discoverProjCta.parentElement.querySelector('.underline').style.opacity = '1';
    });
  }

  // COUNTDOWN
  const countDown = () => {
    const countDownDate = new Date("Jan 5, 2030 15:37:25").getTime()
    const countDiv = document.querySelector('#countdown')

    const x = setInterval(() => {
      const now = new Date().getTime()
      const distance = countDownDate - now
      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)
      countDiv.innerHTML = days + "d " + hours + "h " + minutes + "m " + seconds + "s "

      if (distance < 0) {
        clearInterval(x)
        countDiv.innerHTML = "EXPIRED"
      }
    }, 1000)
  }
  countDown()

  // FAQ
  // to-do: dynamic height of answer!!!!!!!
  const question = document.querySelectorAll('.home__faq__question');
  let pad = 11 
  question.forEach((faq) => {
    let isOpen = false;
    const btn = faq.querySelector('.home__faq__closed');
    const answer = faq.querySelector('.home__faq__open');
    faq.addEventListener('click', () => {
      isOpen = !isOpen;

      //text height
      if (isOpen) answer.style.height = (answer.querySelector('p').clientHeight + pad)+ 'px';
      else answer.style.height = '0px'

      //open
      answer.classList.toggle('open');

      //anim height
      if (isOpen) answer.style.transition = 'height 0.2s linear, opacity 0.2s linear 0.2s';
      else answer.style.transition = 'height 0.2s linear, opacity 0.2s linear';

      faq.querySelector('.faq-open').style.display = isOpen ? 'none' : 'block';
      faq.querySelector('.faq-close').style.display = isOpen ? 'block' : 'none';
    });
  });

  // BACK TO TOP
  // temporary moved to hiw
};
export default HomeLayout;
