const ErrorPage = () => {
  const pageHeight = () => {
    if (window.innerWidth > 768) {
      let footer = document.querySelector('.footer');
      let errorPage = document.querySelector('.errorPage');
      errorPage.style.height =
        'calc(100vh + 92px - ' + footer.clientHeight + 'px';
    }
  };
  pageHeight();
  window.addEventListener('resize', pageHeight);

  //grayscale bg shader
  setTimeout(() => {
    const bgShader = document.querySelector('.bgShader');
    if (bgShader) bgShader.style.filter = 'grayscale(100%) contrast(150%)';
  }, 200);
}
export default ErrorPage;
