import * as THREE from "three";
import * as dat from "dat.gui";
import gsap from 'gsap';
import vertex from "./../shaders/vertex.glsl";
import fragment from "./../shaders/fragment.glsl";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass.js';

// starting point:
// https://codepen.io/bclarke/pen/MWEGRga

const BackgroundShader = () => {

    // SETTINGS
    let debug = false
    const params = {
        color1: { value: new THREE.Color(0.624, 0.549, 0.263) }, //#9F8C43
        color2: { value: new THREE.Color(0.361, 0.451, 0.337) }, // #5C7356
        color3: { value: new THREE.Color(0.592, 0.439, 0.38) }, // #977061
        color4: { value: new THREE.Color(0.973, 0.612, 0.145) }, // #F89C25
        acceleration: 0.06,
        speed: 1,
        grain: 0.10,
        isStatic: true
    };
    
    // SCENE
    const renderer = new THREE.WebGLRenderer({
        antialias: false,
    });
    
    document.body.appendChild(renderer.domElement);
    renderer.domElement.classList.add("bgShader")
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0);
    
    const scene = new THREE.Scene();
    const camera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, 1e-5, 100);
    camera.position.z = 1;
    
    // effet composer
    const composer = new EffectComposer(renderer);
    const renderPass = new RenderPass(scene, camera);
    let filmPass = new FilmPass(params.grain);
    composer.addPass(renderPass);
    composer.addPass(filmPass);
    
    // CONTROLS
    if(debug){
        const gui = new dat.GUI();
        const colors = gui.addFolder("Colors");
        const grain = gui.addFolder("Grain");
        const movement = gui.addFolder("Movement");
        
        colors
            .addColor({ color1: params.color1.value.getHex() }, "color1")
            .onChange((color) => {
            mat.uniforms.color1.value = new THREE.Color(color);
            });
        colors
            .addColor({ color2: params.color2.value.getHex() }, "color2")
            .onChange((color) => {
            mat.uniforms.color2.value = new THREE.Color(color);
            });
        colors
            .addColor({ color3: params.color3.value.getHex() }, "color3")
            .onChange((color) => {
            mat.uniforms.color3.value = new THREE.Color(color);
            });
        colors
            .addColor({ color4: params.color4.value.getHex() }, "color4")
            .onChange((color) => {
            mat.uniforms.color4.value = new THREE.Color(color);
            });
        
        grain.add(params, 'isStatic');
        grain.add(params, "grain", 0, 0.5).step(0.01).onChange((value) => {
            composer.removePass(renderPass);
            composer.removePass(filmPass);
            filmPass = new FilmPass(value);
            composer.addPass(renderPass);
            composer.addPass(filmPass);
        });

        movement.add(params, "acceleration", 0, 1).name("mouse");
        movement.add(params, "speed", 0, 5).step(0.1);
        gui.close()
    }
    
    // MOUSE MOVE
    const mouse = new THREE.Vector2();
    
    //original
    // window.addEventListener("mousemove", (e) => {
    //   mouse.set(
    //     e.clientX / window.innerWidth - 0.5,
    //     1 - e.clientY / window.innerHeight - 0.5
    //   );
    // });
    
    //tweak mouse move
    let mouseX = 0;
    let mouseY = 0;
    let targetMouseX = 0;
    let targetMouseY = 0;
    
    const howDiv = document.querySelector('.home__how');
    let disableBg = howDiv?.getBoundingClientRect().top < window.innerHeight
    window.addEventListener("scroll", () => {
        // to-do: think if there is a better way to disable this beacause there are to many eventlistener in the hp
        // maybe do it in the HIW file
        disableBg = howDiv?.getBoundingClientRect().top < window.innerHeight 
    })
    window.addEventListener("mousemove", (e) => {
        if(!disableBg) { 
            targetMouseX = e.clientX / window.innerWidth - 0.5;
            targetMouseY = 1 - e.clientY / window.innerHeight - 0.5;
        }
    });
    window.addEventListener("touchmove", (e) => {
        if(!disableBg) {
            targetMouseX = e.touches[0].clientX / window.innerWidth - 0.5;
            targetMouseY = 1 - e.touches[0].clientY / window.innerHeight - 0.5;
        }
    });
    
    const updateMousePosition = () => {
        mouseX += (targetMouseX - mouseX) * params.acceleration;
        mouseY += (targetMouseY - mouseY) * params.acceleration;
    
        mouse.set(mouseX, mouseY);
    
        requestAnimationFrame(updateMousePosition);
    };
    updateMousePosition();
    
    // SHADER MATERIAL
    const geo = new THREE.PlaneGeometry(1, 1);
    const mat = new THREE.ShaderMaterial({
        depthTest: false,
        uniforms: {
        uTime: { value: params.speed },
        uMouse: { value: mouse },
        color1: params.color1,
        color2: params.color2,
        color3: params.color3,
        color4: params.color4,
        },
        vertexShader: vertex,
        fragmentShader: fragment,
    });
    
    const plane = new THREE.Mesh(geo, mat);
    scene.add(plane);
    
    function onWindowResize() {
        // camera.aspect = window.innerWidth / window.innerHeight;
        // camera.updateProjectionMatrix();
        renderer.setSize( window.innerWidth, window.innerHeight );
    }
    window.addEventListener("resize", onWindowResize);
    
    // ANIMATE
    const animate = () => {
        mat.uniforms.uTime.value = performance.now() * params.speed;
    
        // renderer.render(scene, camera);
        if(params.isStatic) composer.render(0)
        else composer.render()

        requestAnimationFrame(animate);
    };
    
    requestAnimationFrame(animate);
  
}

export default BackgroundShader;