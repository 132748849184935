import * as THREE from 'three';
import * as dat from 'dat.gui';
import Stats from 'stats.js';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
// import { OrbitControls } from 'three/addons/controls/OrbitControls.js';

const HowItWorks = () => {

  // SETTINGS
  let local = !window.location.href.includes('viv')
  let debug = local;
  const path = document.getElementById('wp-assets-path').value + 'img/';
  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
  }
  // Initialize loading manager
  const manager = new THREE.LoadingManager();
  const loadingLayer = document.querySelector(".loading");
  const percentLoading = loadingLayer.querySelector('span');

    let model0, model1, model2;
    const objDistance = 4;

  manager.onStart = (url, itemsLoaded, itemsTotal) => {
    console.log('loading✨');
  };

  manager.onLoad = () => {
    loadingLayer.classList.add('complete');
    setTimeout(() => loadingLayer.classList.add('remove'), 500);
  };

  manager.onProgress = (url, itemsLoaded, itemsTotal) => {
    let percentComplete = Math.round((itemsLoaded / itemsTotal) * 100);
    console.log(`👀 ${percentComplete}%`);
    percentLoading.innerText = `${percentComplete}%`;
  };

  manager.onError = (url) => {
    console.log('💀 ' + url);
  };

    // Initialize DRACOLoader
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath(path + 'draco/');

  // Preload assets
  const preloadAssets = () => {
    const loader = new GLTFLoader(manager);
    loader.setDRACOLoader(dracoLoader);

    // model 0
    loader.load(
      path + '0.glb',
      (gltf) => {
        model0 = gltf.scene;
        if(isMobile) model0.scale.set(params.mesh0[0].scaleMobile[0], params.mesh0[0].scaleMobile[1], params.mesh0[0].scaleMobile[2]);
        else model0.scale.set(params.mesh0[0].scale[0], params.mesh0[0].scale[1], params.mesh0[0].scale[2]);
        model0.position.set( 0, -objDistance, 0) 
        model0.rotation.set(0, 0, 0);
        model0.children[0].material = matcapMap;
        scene.add(model0);
      }
    );

    // model 1
    loader.load(
      path + '1.glb',
      (gltf) => {
        model1 = gltf.scene;
        if(isMobile) model1.scale.set(params.mesh1[0].scaleMobile[0], params.mesh1[0].scaleMobile[1], params.mesh1[0].scaleMobile[2]);
        else model1.scale.set(params.mesh1[0].scale[0], params.mesh1[0].scale[1], params.mesh1[0].scale[2]);
        model1.position.set(0, -objDistance, 0) 
        model1.rotation.set(0, 0, 0);
        model1.children[0].material = matcapMap;
        scene.add(model1);
      }
    );

    // model 2
    loader.load(
      path + '2.glb',
      (gltf) => {
        model2 = gltf.scene;
        if(isMobile) model2.scale.set(params.mesh2[0].scaleMobile[0], params.mesh2[0].scaleMobile[1], params.mesh2[0].scaleMobile[2]);
        else model2.scale.set(params.mesh2[0].scale[0], params.mesh2[0].scale[1], params.mesh2[0].scale[2]);
        model2.position.set( 0, -objDistance, 0) 
        model2.rotation.set(params.mesh2[0].rotation[0], 0, 0);
        model2.children[0].material = matcapMap;
        scene.add(model2);
      }
    );

  };

  // Call preloadAssets to start loading immediately
  preloadAssets();

  
  const stats = new Stats();
  if(debug) {
    stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
    document.body.appendChild(stats.dom);
  }

  const params = {
    materialColor: '#ff9500',
    specularColor: '#ffffff',
    shininess: 0.88,
    reflectivity: 1,
    opacity: 0.95,
    mesh0: [
      {
        position: [-1.0462174737204904, -0.6, -0.8],
        positionMob: [-0.1, -0.2, -0.8],
        rotation: [-0.8,-0.2,0.3],
        scale: [7, 7, 7],
        scaleMobile: [5, 5, 5]
      },
      {
        position: [-1.3, 0.1, 1],
        positionMob: [0, 0.25, 0],
        rotation: [0, 1.2, -1],
        scale: [7,7,7],
        scaleMobile: [5, 5, 5]
      }
    ],
    mesh1: [
      {
        position: [-0.4, -0.55, 0.7],
        positionMob: [0.1, -0.15, 0.7],
        rotation: [0.35865962641405646, 0.07527424258072779, 0.21696693449739257],
        scale: [7.8259337561663145, 7.713178294573644, 7.8259337561663145],
        scaleMobile: [5, 5, 5]
      },
      {
        position: [-0.4, -0.3, 1.8],
        positionMob: [0.2, 0.15, 1.8],
        rotation: [0.4, -1.2, 1.5],
        scale: [7.8259337561663145, 7.713178294573644, 7.8259337561663145],
        scaleMobile: [5, 5, 5]
      },
      {
        position: [0, 0.15, 1.1],
        positionMob: [0.2, 0.15, 1.1],
        rotation: [0.4, -0.3, 1.5],
        scale: [7.65466138204377, 7.713178294573644, 7.8259337561663145],
        scaleMobile: [5, 5, 5]
      }
    ],
    mesh2: [{
      position: [-0.8879492600422836, 0.014094432699083725, -4.27061310782241],
      positionMob: [0, 0.25, -6],
      rotation: [-3.141592653589793, 0.8545840481223816, 0.6420450102473856],
      scale: [5.570824524312896, 7, 7],
      scaleMobile: [5, 5, 5]
    }],
  };

  const transitionSect = {
    duration: 1,
    ease: 'power2.inOut',
  };
  const transitionShort = {
    duration: 1.2,
    ease: 'power2.inOut',
  };
  const transitionMedium = {
    duration: 1.5,
    ease: 'power2.inOut',
  };

  // SCENE
  const scene = new THREE.Scene();
  const renderer = new THREE.WebGLRenderer({
    antialias: true,
  });

  const howDiv = document.querySelector('.home__how');
  const introDiv = document.querySelector('.home__intro');
  howDiv.appendChild(renderer.domElement);
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setClearColor(0xffffff, 0);

  const header = document.querySelector('header')
  const logo = header.querySelector('.logo')
  const menuToggle = document.querySelector('.menu__toggle');
  const svg = menuToggle.querySelector('img');

  // text
  const section = howDiv.querySelectorAll('.home__how__section');
  const title = howDiv.querySelectorAll('.home__how__title')
  const subtitle = howDiv.querySelectorAll('.home__how__subtitle');
  title[0].style.opacity = '0';

  // bg
  const bgShader = document.querySelector('.bgShader')
  bgShader.style.filter = 'grayscale(0%) contrast(100%)'

  // camera
  const cameraGroup = new THREE.Group()
  scene.add(cameraGroup)

  const camera = new THREE.PerspectiveCamera( 30, sizes.width / sizes.height, 1, 100 );
  camera.position.z = 4;
  cameraGroup.add(camera)

  // controls
  // const controls = new OrbitControls(camera, renderer.domElement);
  // controls.enableZoom = false;
  // controls.enableRotate = false;

  // MAT
  const textureLoader = new THREE.TextureLoader();
  const matcapTexture = textureLoader.load(path + 'matcap_main.jpg'); 
  const matcapMap = new THREE.MeshMatcapMaterial({
    opacity: params.opacity,
    transparent: true,
    matcap: matcapTexture
  });

  // PRELOAD 
  // const manager = new THREE.LoadingManager();
  // const loadingLayer = document.querySelector(".loading")
  // const percentLoading = loadingLayer.querySelector('span')

  // const preload = () => {
  //   manager.onLoad = () => {
  //     console.log('LOADED 🤪✨✨');
  //     setTimeout(() => {
  //       loadingLayer.classList.add('complete')
  //       setTimeout(() => {
  //         loadingLayer.classList.add('remove')
  //       }, 500);
  //     }, 500);
      
  //   };
  //   manager.onProgress = (url, itemsLoaded, itemsTotal) => {
  //     let percentComplete = Math.round((itemsLoaded / itemsTotal) * 100);
  //     console.log(`👀 ${percentComplete}%`);
  //     percentLoading.innerText = `${percentComplete}%`
  //   };
  //   manager.onError = (url) => {
  //     console.log('💀 ' + url);
  //   };
  // }

  // MODELS
  // let model0, model1, model2;
  // const loader = new GLTFLoader(manager);
  // loader.setDRACOLoader(dracoLoader);
  // const objDistance = 4;

  // // model 0
  // loader.load(
  //   path + '0.glb',
  //   (gltf) => {
  //     model0 = gltf.scene;
  //     if(isMobile) model0.scale.set(params.mesh0[0].scaleMobile[0], params.mesh0[0].scaleMobile[1], params.mesh0[0].scaleMobile[2]);
  //     else model0.scale.set(params.mesh0[0].scale[0], params.mesh0[0].scale[1], params.mesh0[0].scale[2]);
  //     model0.position.set( 0, -objDistance, 0) 
  //     model0.rotation.set(0, 0, 0);
  //     model0.children[0].material = matcapMap;
  //     scene.add(model0);
  //   }
  // );

  // // model 1
  // loader.load(
  //   path + '1.glb',
  //   (gltf) => {
  //     model1 = gltf.scene;
  //     if(isMobile) model1.scale.set(params.mesh1[0].scaleMobile[0], params.mesh1[0].scaleMobile[1], params.mesh1[0].scaleMobile[2]);
  //     else model1.scale.set(params.mesh1[0].scale[0], params.mesh1[0].scale[1], params.mesh1[0].scale[2]);
  //     model1.position.set(0, -objDistance, 0) 
  //     model1.rotation.set(0, 0, 0);
  //     model1.children[0].material = matcapMap;
  //     scene.add(model1);
  //   }
  // );

  // // model 2
  // loader.load(
  //   path + '2.glb',
  //   (gltf) => {
  //     model2 = gltf.scene;
  //     if(isMobile) model2.scale.set(params.mesh2[0].scaleMobile[0], params.mesh2[0].scaleMobile[1], params.mesh2[0].scaleMobile[2]);
  //     else model2.scale.set(params.mesh2[0].scale[0], params.mesh2[0].scale[1], params.mesh2[0].scale[2]);
  //     model2.position.set( 0, -objDistance, 0) 
  //     model2.rotation.set(params.mesh2[0].rotation[0], 0, 0);
  //     model2.children[0].material = matcapMap;
  //     scene.add(model2);
  //   }
  // );

  // SCROLL
  let howScroll, sectionPos, currentSection, newSection;
  let scrolling = false
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  const modelIn = (mesh, params, transition, index, fixY) => {
    gsap.to(mesh.position, {
      x: isMobile ? params.positionMob[0] : params.position[0],
      y: isMobile ? params.positionMob[1] : params.position[1],
      z: isMobile ? params.positionMob[2] : params.position[2],
      ...transition,
    });
    gsap.to(mesh.rotation, {
      x: params.rotation[0],
      y: fixY ? -params.rotation[1] : params.rotation[1],
      z: params.rotation[2],
      ...transition,
    });
  }

  const modelOut = (mesh, y, duration, index, fixX, val, easeOut) => {
    gsap.to(mesh?.position, {
      x: 0,
      y: y,
      z: 0,
      duration: duration,
      ease: easeOut ? 'power2.out' : 'power2.inOut',
    });
    gsap.to(mesh?.rotation, {
      x: fixX ? val : 0,
      y: 0,
      z: 0,
      duration: duration,
      ease: easeOut ? 'power2.out' : 'power2.inOut',
    });
  }

  const textIn = (n, del) => {
    gsap.to(document.querySelectorAll('.content')[n], {
      top: '0%',
      ...transitionShort,
      delay: del ? 0.25 : 0
    });
  };
  
  const textOut = (n, d) => {
    if(n||n==0) {
      gsap.to(document.querySelectorAll('.content')[n], {
        top: '-100%',
        ...transitionShort,
      });
    }
    if(d||d==0){
      gsap.to(document.querySelectorAll('.content')[d], {
        top: '100%',
        ...transitionShort,
      });
    }
  };

  // intersection observer
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  };

  const targets = document.querySelectorAll('.home__intro, .home__first-project, .home__subscribe, .home__faq, .home__back-top');
  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        sectionIn(entry.target);
        observer.unobserve(entry.target);
      }
    });
  }, options);

  targets.forEach((target) => {
    target.children[0].style.opacity = 0;
    observer.observe(target);
  });

  const sectionIn = (target) => {
    gsap.fromTo(
      target.children[0],
      { y: 80, opacity: 0 },
      { y: 0, opacity: 1, stagger: 1, ...transitionSect }
    );
  };

  const showHeader = (show) => {
    if(!isMobile)return
    if (show) {
      gsap.to(header, { background:'rgb(255 255 255 / 1)', duration: 0.5, delay: 1});
      gsap.to(logo, { opacity: 1, duration: 0.5, delay: 1});
      logo.style.pointerEvents = 'auto';
      // setTimeout(() => {
      //   svg.classList.add('dark');
      // }, 700);
    } else {
      gsap.to(header, { background:'rgb(255 255 255 / 0)', duration: 0.5});
      gsap.to(logo, { opacity: 0, duration: 0.5});
      logo.style.pointerEvents = 'none';
      // svg.classList.remove('dark');
    }
  }


  // mobile
  // to-do: also check width or something to avoid touch screen laptops
  function checkMobile() {
    return (
      window.innerWidth <= 1024 && (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0 )
    );
  }
  let isMobile = checkMobile()
  let isBottomContainerInView = false
  const bottomContainer = document.querySelector('.home__bottom-container')
  const observerBottom = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      isBottomContainerInView = entry.isIntersecting;
    })
  }, { threshold: 0.5 })
  
  observerBottom.observe(bottomContainer)
  
  function canScrollUp(container) {
    return container.scrollTop > 0
  }

  // scroll
  document.body.style.overflow = 'hidden';
  if(isMobile){
    let startY = 0
    window.addEventListener('touchstart', function (e) {
      startY = e.touches[0].clientY
    });
    window.addEventListener('touchmove', (e) => {
      let currentY = e.touches[0].clientY
      let deltaY = startY - currentY
  
      if (!isBottomContainerInView || !canScrollUp(bottomContainer)) {
        const direction = deltaY > 0 ? 'down' : 'up'
        handleScroll(direction)
        startY = currentY
      } else {
        e.preventDefault()
      }
    })
  } else {
    window.addEventListener('wheel', (e) => {
      sectionPos = howScroll / sizes.height;
    
      const direction = e.deltaY > 0 ? 'down' : 'up';
      handleScroll(direction)
    })

  } 

  function updateCalculations() {
    const scrollY = window.scrollY;
    howScroll = scrollY - howDiv.offsetTop;
    sectionPos = howScroll / sizes.height;
  }

  const handleScroll = (dir) => {
    if(document.querySelector('.menu__toggle').classList.contains("open")) {
      return
    }

    const scrollY = window.scrollY;
    howScroll = scrollY - howDiv.offsetTop;
    sectionPos = howScroll / sizes.height;
    // updateCalculations()

          
    // to-do: refactorrrr
    if (!scrolling && dir === 'up') {
      if (sectionPos <= 6) {
        newSection = Math.floor(currentSection - 1);
      }
    }
    if (!scrolling && dir === 'down') {
      if (sectionPos < -1) {
        newSection = Math.round(sectionPos + 1);
      } else {
        newSection = Math.ceil(sectionPos + 1);
      }
    }

    // to-do: wip last section scroll up
    if (!isMobile) {
      if (newSection > 6) {
        document.body.style.overflow = '';
      } else {
        if (dir === 'up') {
          document.body.style.overflow = 'hidden';
        } 
      }
    }
    // if (window.scrollY >= (document.body.scrollHeight - sizes.height)-30) {
    //   if (dir === 'down') {
    //     document.body.style.overflow = 'hidden';
    //   } else {
    //     document.body.style.overflow = '';
    //   }
    // } 
    

    if (newSection != currentSection) {
      // console.log("section ", newSection)
      currentSection = newSection;

      // intro text & bg shader 
      if (currentSection < 0 && dir === 'up') {
        gsap.to(title[0], { opacity: 0, duration: 0.7, ease: 'power2.out' });
        gsap.to(bgShader, { filter: 'grayscale(0%) contrast(100%)', duration: 1 });
        gsap.to( howDiv, { background: "rgb(255 255 255 / 1)", duration: 0.5, });
        gsap.to( introDiv, { background: "rgb(255 255 255 / 1)", duration: 0.5, });
      }
      if (currentSection == 0 && dir === 'down') {
        gsap.fromTo(
          title[0],
          { y: 200 },
          { opacity: 1, x: textPosition.x, y: textPosition.y, duration: 0.7, delay: 0.5, ease: 'power2.out' }
        );
        gsap.to( howDiv, { background: "rgb(255 255 255 / 0.5)", duration: 0.7, delay: 0.5 });
        gsap.to( introDiv, { background: "rgb(255 255 255 / 0.5)", duration: 0.7, delay: 0.5 });
        gsap.to(bgShader, { filter: 'grayscale(100%) contrast(150%)', duration: 1 });
      } 

      // sections
      if (!click && currentSection >= -2 && ((dir == "down" && currentSection <= 6) || (dir == "up" && currentSection <= 5))) {
        scrolling = true
        let newPos = currentSection * sizes.height + howDiv.offsetTop;
        
        let menuToggle = document.querySelector('.menu__toggle').querySelector('.visible')

        gsap.to(window, {
          scrollTo: newPos,
          duration: 1.5,
          ease: 'power2.inOut',
          onComplete: () => {
            scrolling = false;
          },
        });

        setTimeout(() => {
          if (currentSection == -1 && dir === 'down') {
            menuToggle.classList.add('dark');
          }
          if (currentSection == -2 && dir === 'up') {
            menuToggle.classList.remove('dark');
          }
        }, 500);

        // models
        // IN
        if (currentSection < 0) {
          // change model
          textOut(null, 0);
          modelOut(model0, -objDistance, 1);
        }

        // MODEL 0
        if (currentSection == 0) {
          textOut(0, 1);
          textIn(0);
          modelIn(model0, params.mesh0[0], transitionShort);
        } else if (currentSection == 1) {
          // change model 
          textOut(0, 2)
          modelOut(model1, -objDistance, 1.5);
          
          textIn(1)
          modelIn(model0, params.mesh0[1], transitionShort)
        }

        // MODEL 1
        if (currentSection == 2) {
          // change model 
          textOut(1, 3);
          modelOut(model0, objDistance, 1.5);

          textIn(2)
          modelIn(model1, params.mesh1[0], transitionShort);

        } else if (currentSection == 3) {
          textOut(2, 4);

          modelIn(model1, params.mesh1[1], transitionShort);
          textIn(3);
        } else if (currentSection == 4) {
          // change model 
          textOut(3, 5);
          modelOut(model2, -objDistance*2, 1.5);

          modelIn(model1, params.mesh1[2], transitionShort, true);
          textIn(4);
        }

        // MODEL 2
        if (currentSection == 5) {
          // change model 
          textOut(4);
          modelOut(model1, objDistance, 1.5 );

          textIn(5,  dir === 'up' && isMobile ? true : false);
          modelIn(model2, params.mesh2[0], dir === 'down' ? transitionShort:transitionMedium);
        }

        // OUT
        if (currentSection > 5) {
          // change model 
          textOut(5);
          modelOut(model2, objDistance*2, 1.2, true, params.mesh2[0].rotation[0], true);
          
          showHeader(true)
        } else {
          showHeader(false) 
        }
      }
    }
  };

  // BACK TO TOP BTN
  let click = false
  let scrollToTopButton = document.querySelector('.home__back-top');
  let hoverBack = document.querySelector('.home__back-top__button-hover');
  const backToTop = () => {
    click = true;
    
    // resets texts
    document.querySelectorAll('.content').forEach((el)=>{
      el.style.top = "100%"
      el.style.visibility = 'hidden';
    })

    // reset models
    if(model0 && model1 && model2){
      model0.position.set(0, -objDistance, 0);
      model1.position.set(0, -objDistance, 0);
      model2.position.set(0, -objDistance*2, 0);
    }

    //reset initial 
    gsap.to(bgShader, { filter: 'grayscale(0%) contrast(100%)', duration: 1 });
    gsap.to(howDiv, { background: 'rgb(255 255 255 / 1)', duration: 0.5 });
    gsap.to(introDiv, { background: 'rgb(255 255 255 / 1)', duration: 0.5 });
    document.body.style.overflow = 'hidden';
    currentSection=-2

    //menu icon
    const svg = menuToggle.querySelectorAll('img');
    svg[0].classList.remove('dark');

    // back to top
    let mob = window.innerWidth <= 1024; //to-do check if touch ipad (also about)
    gsap.to(window, {
      scrollTo: 0,
      ...transitionShort,
      onComplete: () => {
        click = false;
        if (mob) hoverBack.classList.add('reset');
        const texts = document.querySelectorAll('.content');
        texts.forEach((text) => {
          text.style.visibility = 'visible';
        });
      },
    });
    if (mob) hoverBack.classList.remove('reset');
  }
  scrollToTopButton.addEventListener('click', backToTop);


  // SCROLL TO FOOTER
  // let scrollToFooterButton = document.querySelector('.home__cta');
  // const scrollToFooter = () => {
  //   click = true;

  //   const texts = document.querySelectorAll('.content');
  //   texts.forEach((text) => {
  //     text.style.visibility = "hidden"
  //   });

  //   //menu icon
  //   const svg = menuToggle.querySelectorAll('img');
  //   svg[0].classList.add('dark');

  //   // set models to end position
  //   if (model0 && model1 && model2) {
  //     model0.position.set(
  //       isMobile ? params.mesh0[1].positionMob[0] : params.mesh0[1].position[0],
  //       objDistance,
  //       isMobile ? params.mesh0[1].positionMob[2] : params.mesh0[1].position[2]
  //     );
  //     model1.position.set(
  //       isMobile ? params.mesh1[2].positionMob[0] : params.mesh1[2].position[0],
  //       objDistance,
  //       isMobile ? params.mesh1[2].positionMob[2] : params.mesh1[2].position[2]
  //     );
  //     model2.position.set(
  //       isMobile ? params.mesh2[0].positionMob[0] : params.mesh2[0].position[0],
  //       objDistance*2,
  //       isMobile ? params.mesh2[0].positionMob[2] : params.mesh2[0].position[2]
  //     );
  //   }

  //   // set texts to end position
  //   for(let i = 0; i<=5; i++) {
  //     textOut(i)
  //   }

  //   setTimeout(() => {
  //     bgShader.style.filter = 'grayscale(100%) contrast(150%)';
  //   }, 500);
    
  //   // scroll to footer

  //   //fix internal scroll
  //   let internalScroll = document.querySelector('.home__bottom-container') 
  //   let internalScrollHeight = document.querySelector('.home__faq').clientHeight + document.querySelector('.home__back-top').clientHeight
  //   internalScroll.scrollTo(0, internalScrollHeight)

  //   gsap.to(window, {
  //     scrollTo: document.documentElement.scrollHeight,
  //     duration: 1.5,
  //     onComplete: () => {
  //       click = false;
  //       currentSection = 8; //change if change section
  //       newSection = 8; //change if change section
  //       document.body.style.overflow = '';
  //       howDiv.style.background = 'rgb(255 255 255 / 0.5)';
  //       bgShader.style.filter = 'grayscale(100%) contrast(150%)';
  //       texts.forEach((text) => {
  //         text.style.visibility = 'visible';
  //       });
  //     },
  //   });
  // };
  // scrollToFooterButton.addEventListener('click', scrollToFooter);

  // ON LOAD
  // if(!debug){
    window.onload = () => {
      // console.log("🔄 reload")
      // preload()

      //header mob
      if (isMobile) {
        header.style.background = 'rgb(255 255 255 / 0)';
        logo.style.opacity = 0;
        logo.style.pointerEvents = 'none';
      }

      gsap.to(window, {
        scrollTo: 0,
        duration: 0.1,
        onComplete: () => {
          // click = false;
          // preload()
        },
      });
    }
  // }

  // PARALLAX
  let cursor = {}
  cursor.x = 0
  cursor.y = 0
  window.addEventListener('mousemove', (event) => {
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
  });

  // CONTROLS
  // if (debug) {
  //   const gui = new dat.GUI();
  //   gui.add(camera, 'fov', 0, 200).onChange((value) => {
  //     camera.fov = value;
  //     camera.updateProjectionMatrix();
  //   });
  // }

  window.addEventListener('resize', () => {
    isMobile = checkMobile();
    
    // hard reload 
    if(!isMobile){
      window.location.reload()
    }

    // to-do: try to avoid hard reload with theese:
    sizes.width = window.innerWidth,
    sizes.height = window.innerHeight,
    renderer.setSize(window.innerWidth, window.innerHeight);
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
  });

//   function debounce(func, wait) {
//   let timeout;
//   return function executedFunction(...args) {
//     const later = () => {
//       clearTimeout(timeout);
//       func(...args);
//     };
//     clearTimeout(timeout);
//     timeout = setTimeout(later, wait);
//   };
// }

// // Use the debounce function to wrap your resize logic
// const handleResize = debounce(() => {
//   sizes.width = window.innerWidth;
//   sizes.height = window.innerHeight;
//   renderer.setSize(window.innerWidth, window.innerHeight);
//   camera.aspect = window.innerWidth / window.innerHeight;
//   camera.updateProjectionMatrix();

//   updateCalculations();

//   // Refresh ScrollTrigger instances if using GSAP
//   ScrollTrigger.refresh();

//   // Additional recalculations as needed
// }, 250);

// window.addEventListener('resize', handleResize);


  // FOCUS
  function hasFocus() {
    return document.visibilityState === 'visible' && document.hasFocus();
  }
  document.addEventListener('visibilitychange', hasFocus);

  // ANIMATE
  let textPosition = { x: 0, y: 0 };
  let previousTime = 0
  const clock = new THREE.Clock()
  const animate = () => {
    if (debug) {
      stats.begin();
    }

    const elapsedTime = clock.getElapsedTime();
    const deltaTime = elapsedTime - previousTime;
    previousTime = elapsedTime;


    // PARALLAX
    if(hasFocus() && !isMobile){
      const parallaxX = cursor.x * 0.2;
      const parallaxY = -cursor.y * 0.2;
      cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * deltaTime;
      cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * deltaTime;
      
      textPosition.x += (cursor.x * 50 - textPosition.x) * deltaTime;
      textPosition.y += (cursor.y * 50 - textPosition.y) * deltaTime;
      
      if(currentSection || currentSection==0){
        if(title[currentSection]){
          title[currentSection].style.transform = `translate(${textPosition.x}px, ${textPosition.y}px)`;
        }
        if(subtitle[currentSection-1]){
          subtitle[currentSection-1].style.transform = `translate(${textPosition.x}px, ${textPosition.y}px)`;
        }
      }
    }

    // rotation
    // if (currentSection === 1 || currentSection === 2) {
    //   model0.rotation.x += cursor.x * 0.15 * deltaTime;
    //   model0.rotation.y += cursor.y * 0.15 * deltaTime;
    // } else if (currentSection === 3 || currentSection === 4 || currentSection === 5) {
    //   model1.rotation.x += cursor.x * 0.15 * deltaTime;
    //   model1.rotation.y += cursor.y * 0.15 * deltaTime;
    // } else if (currentSection === 6) {
    //   model2.rotation.x += cursor.x * 0.15 * deltaTime;
    //   model2.rotation.y += cursor.y * 0.15 * deltaTime;
    // } 

    // controls.update();
    renderer.render(scene, camera);
    camera.updateProjectionMatrix();
    requestAnimationFrame(animate);

    if (debug) {
      stats.end();
    }
    
  };
  
  requestAnimationFrame(animate);

};

export default HowItWorks;
